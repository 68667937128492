<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t("report_commission") }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :loading="exportLoading"
          outlined
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>

        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters
        :default-status="2"
        @onSendDate="addPayload"
      ></DateFilters>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-select
            v-model.trim="pointSelect"
            :items="pointList"
            :label="$t('point')"
            :item-text="$i18n.locale"
            item-value="value"
            dense
            outlined
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
          class="py-0"
        >
          <v-text-field
            v-model.trim="searchtext"
            dense
            outlined
            :label="`${$t('search')} ${$t('pressEnterForSearch')}`"
            @keypress.enter="addPayload(payload.start, payload.end)"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.number`]="{ index }">
          {{ index+1 }}
        </template>

        <template v-slot:[`item.order_id`]="{ item }">
          <span
            class="primary--text font-weight-semibold"
            style="cursor:pointer"
            @click="dataOrderDetail = item;isShowOrderDetail = true"
          >
            {{ item.order_id }}
          </span>
        </template>
        <template v-slot:[`item.order_create`]="{ item }">
          <ConverseDate :date="item.order_create" />
        </template>
        <template v-slot:[`item.order_detail`]="{ item }">
          <ul>
            <li
              v-for="(x,index) in item.order_detail"
              :key="index"
            >
              {{ x.orderdetail_name }}* {{ x.orderdetail_amount }}
            </li>
          </ul>
        </template>
        <template v-slot:[`item.order_point`]="{ item }">
          <span v-if="item.order_point!= $t('total')">
            {{ item.order_point | formatPrice }}
          </span>
          <span
            v-else
            class="primary--text font-weight-bold"
          >
            {{ item.order_point }}
          </span>
        </template>
        <template v-slot:[`item.order_total`]="{ item }">
          <span v-if="item.order_point!= $t('total')">
            {{ item.order_total | formatPrice }}
          </span>
          <span
            v-else
            class="primary--text font-weight-bold"
          >
            {{ item.order_total | formatPrice }}
          </span>
        </template>
        <template v-slot:[`item.order_totalpay`]="{ item }">
          <span v-if="item.order_point!= $t('total')">
            {{ item.order_totalpay | formatPrice }}
          </span>
          <span
            v-else
            class="primary--text font-weight-bold"
          >
            {{ item.order_totalpay | formatPrice }}
          </span>
        </template>
        <template v-slot:[`item.order_save_amount`]="{ item }">
          <span v-if="item.order_point!= $t('total')">
            {{ item.order_save_amount | formatPrice }}
          </span>
          <span
            v-else
            class="primary--text font-weight-bold"
          >
            {{ item.order_save_amount | formatPrice }}
          </span>
        </template>
        <template v-slot:[`item.fee`]="{ item }">
          <span v-if="item.order_point!= $t('total')">
            {{ item.fee | formatPrice }}
          </span>
          <span
            v-else
            class="primary--text font-weight-bold"
          >
            {{ item.fee | formatPrice }}
          </span>
        </template>
        <template v-slot:[`item.net_amount`]="{ item }">
          <span v-if="item.order_point!= $t('total')">
            {{ item.net_amount | formatPrice }}
          </span>
          <span
            v-else
            class="primary--text font-weight-bold"
          >
            {{ item.net_amount | formatPrice }}
          </span>
        </template>
      </v-data-table>
    </v-card>
    <OrderDetail
      v-model="isShowOrderDetail"
      :order-detail="dataOrderDetail"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { removeComma } from '@/views/reports/useExcel'
import { sumdate, sumdateEn, formatPrice } from '@/plugins/filters'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import vatDataForSearch from '@/@fake-db/data/vatDataForSearch.json'
import pointTypeList from '@/@fake-db/data/pointTypeList.json'
import crmLine from '@/api/CRM-line/crmLine'
import OrderDetail from './OrderDetail.vue'

export default {
  components: {
    OrderDetail,
    DateFilters,
    ConverseDate,
  },
  filters: {
    formatPrice,
  },
  setup() {
    const XLSX = require('xlsx')
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      {
        text: i18n.t('receipt_number'), value: 'order_id', width: 120,
      },
      { text: i18n.t('customer'), value: 'online_fullname', width: 180 },
      { text: i18n.t('list'), value: 'order_detail', width: 400 },
      { text: i18n.t('date_of_receipt'), value: 'order_create', width: 150 },
      { text: i18n.t('payment_channel'), value: 'shop_bank', width: 150 },
      {
        text: i18n.t('point'), value: 'order_point', width: 100, align: 'end',
      },
      {
        text: i18n.t('net_amount'), value: 'order_total', width: 210, align: 'end',
      },
      {
        text: i18n.t('discount'), value: 'order_save_amount', width: 210, align: 'end',
      },
      {
        text: i18n.t('total_payment'), value: 'order_totalpay', width: 210, align: 'end',
      },
      {
        text: i18n.t('serviceChangeTenPercent'), value: 'fee', width: 210, align: 'end',
      },
      {
        text: i18n.t('net_profit'), value: 'net_amount', width: 210, align: 'end',
      },
      { text: i18n.t('note'), value: 'order_comment', width: 200 },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(true)
    const header = ref('')
    const payload = ref({})
    const taxSelect = ref('')
    const searchtext = ref('')
    const pointSelect = ref('')
    const taxList = ref(vatDataForSearch.data)
    const pointList = ref(pointTypeList.data)
    const exportLoading = ref(false)
    const isShowOrderDetail = ref(false)
    const dataOrderDetail = ref({})
    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        order_point_id: pointSelect.value,
        searchtext: searchtext.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    const serachReport = async payload => {
      loading.value = true
      const { start, end } = payload
      crmLine.reportSaleSummary(payload).then(res => {
        dataTableList.value = res.data
        dataTableList.value.push(
          {
            order_point: `${i18n.t('total')}`,
            order_total: res.order_total,
            order_save_amount: res.total_discount,
            order_totalpay: res.total_pay,
            fee: res.total_fee,
            net_amount: res.net_amount,
          },
        )
        header.value = `${i18n.t('report_commission')} ${i18n.t(
          'since',
        )} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)}`
        loading.value = false
        options.value.itemsPerPage = -1
      })
    }

    watch([taxSelect, pointSelect], () => {
      addPayload(payload.value.start, payload.value.end)
    })

    const exportExcel = async () => {
      exportLoading.value = true
      const myData = await removeComma(
        JSON.parse(JSON.stringify(dataTableList.value)),
      )
      let dataExport = myData.map(item => {
        item.number += 1
        item.detail = `${item.detail ? item.detail : ''}`
        if (item.order_create) {
          item.order_create = i18n.locale == 'th' ? sumdate(item.order_create) : sumdateEn(item.order_create)
        }
        delete item.order_id_pri
        delete item.order_pointsave

        return item
      })
      dataExport = await removeComma(
        JSON.parse(JSON.stringify(dataExport)),
      )
      const fileName = `${header.value}.xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${header.value}`],
        [
          '#',
          i18n.t('receipt_number'),
          i18n.t('customer'),
          i18n.t('list'),
          i18n.t('date_of_receipt'),
          i18n.t('payment_channel'),
          i18n.t('point'),
          i18n.t('net_amount'),
          i18n.t('discount'),
          i18n.t('total_payment'),
          i18n.t('serviceChangeTenPercent'),
          i18n.t('net_profit'),
          i18n.t('note'),
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: [
          'number',
          'order_id',
          'online_fullname',
          'order_detail',
          'order_create',
          'shop_bank',
          'order_point',
          'order_total',
          'order_save_amount',
          'order_totalpay',
          'fee',
          'net_amount',
          'order_comment',
        ],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }]
      const wscols = [
        { wch: 6 },
        { wch: 15 },
        { wch: 25 },
        { wch: 15 },
        { wch: 25 },
        { wch: 40 },
        { wch: 15 },
        { wch: 15 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 20 },
        { wch: 40 },
        { wch: 40 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, i18n.t('report_receipt_summary'))

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      isShowOrderDetail,
      dataOrderDetail,
      XLSX,
      columns,
      dataTableList,
      options,
      loading,
      header,
      taxList,
      taxSelect,
      pointSelect,
      searchtext,
      pointList,
      exportLoading,
      payload,
      addPayload,
      exportExcel,
      serachReport,
      mdiFileExcelOutline,
    }
  },
}
</script>
