<template>
  <v-dialog v-model="isShowDetailOrder" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span> {{ $t('orderdetial') }} {{ localOrderDetail.order_id }} </span>
        <v-spacer></v-spacer>
        <span> {{ $t('customer') }} : {{ localOrderDetail.online_fullname }} </span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="7" lg="8">
            <v-data-table
              :headers="columns"
              :items="cartList"
              :options.sync="options"
              :loading="cartLoading"
              mobile-breakpoint="0"
              dense
              fixed-header
              disable-sort
              hide-default-footer
              :loading-text="$t('data_loading')"
              :no-data-text="$t('no_information')"
            >
              <template v-slot:[`item.id`]="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:[`item.orderdetail_amount`]="{ item }">
                {{ item.orderdetail_amount | formatPrice }}
              </template>
              <template v-slot:[`item.orderdetail_total`]="{ item }">
                {{ item.orderdetail_total | formatPrice }}
              </template>
            </v-data-table>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" md="5" lg="4">
            <v-row>
              <v-col cols="6" class="font-weight-semibold text-lg">
                {{ $t('total_price') }}
              </v-col>
              <v-col cols="6" class="text-right text-lg">
                {{ localOrderDetail.order_total | formatPrice }}
              </v-col>
              <v-col cols="6" class="font-weight-semibold text-lg">
                {{ $t('discount') }}
              </v-col>
              <v-col cols="6" class="text-right text-lg">
                {{ localOrderDetail.order_save_amount | formatPrice }}
              </v-col>
              <v-col cols="6" class="font-weight-semibold text-lg">
                {{ $t('discount_points') }}
              </v-col>
              <v-col cols="6" class="text-right text-lg">
                {{ localOrderDetail.order_pointsave | formatPrice }}
              </v-col>
              <v-col cols="6" class="font-weight-semibold text-lg">
                {{ $t('receive_points') }}
              </v-col>
              <v-col cols="6" class="text-right text-lg">
                {{ localOrderDetail.order_point | formatPrice }}
              </v-col>
              <v-col cols="6" class="font-weight-semibold text-lg">
                {{ $t('payment_channel') }}
              </v-col>
              <v-col cols="6" class="text-right text-lg">
                {{ localOrderDetail.shop_bank }}
              </v-col>
              <v-col cols="6" class="font-weight-semibold text-lg">
                {{ $t('total_payment') }}
              </v-col>
              <v-col cols="6" class="text-right text-lg">
                {{ localOrderDetail.order_totalpay | formatPrice }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <span> {{ $t('date') }} : {{ localOrderDetail.order_create | sumdate }} </span>
        <v-spacer></v-spacer>
        <v-btn color="secondary" outlined @click="$emit('update:isShowDetailOrder', false)">
          {{ $t('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { i18n } from '@/plugins/i18n'
import { ref, watch } from '@vue/composition-api'
import { formatPrice, sumdate } from '@/plugins/filters'

export default {
  filters: {
    formatPrice,
    sumdate,
  },
  model: {
    prop: 'isShowDetailOrder',
    event: 'update:isShowDetailOrder',
  },
  props: {
    isShowDetailOrder: {
      type: Boolean,
      default: false,
    },
    orderDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const columns = ref([
      { text: '#', value: 'id', width: 30 },
      { text: i18n.t('code'), value: 'orderdetail_id', width: 100 },
      { text: `${i18n.t('list')}/${i18n.t('service')}`, value: 'orderdetail_name', width: 200 },
      {
        text: i18n.t('amount'),
        value: 'orderdetail_amount',
        align: 'end',
        width: 80,
      },
      {
        text: i18n.t('total_price'),
        value: 'orderdetail_total',
        align: 'end',
        width: 100,
      },
    ])
    const cartList = ref([])
    const cartLoading = ref(false)
    const options = ref({})
    const localOrderDetail = ref({})
    watch(
      () => props.isShowDetailOrder,
      val => {
        if (val) {
          cartLoading.value = true
          cartList.value = props.orderDetail.order_detail
          localOrderDetail.value = JSON.parse(JSON.stringify(props.orderDetail))
          cartLoading.value = false
        }
      },
    )

    return {
      localOrderDetail,
      columns,
      cartList,
      cartLoading,
      options,
    }
  },
}
</script>
