import refreshToken from '@/plugins/refresh-token'
import { api } from '../mainNode'

const productGet = async id => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'crm/product',
      param: id,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add product by id err : ', err)

      return {}
    })

  return response
}

const productList = async body => {
  //await refreshToken()
  const response = await api
    .post({
      path: 'crm/product/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get product list err : ', err)

      return {}
    })

  return response
}

const productCategoryList = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'crm/product/category',
    })
    .then(res => res.data || [])
    .catch(err => {
      console.log('get product category list err : ', err)

      return []
    })

  return response
}

const productAddDrug = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'crm/product/drug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add product drug err : ', err)

      return {}
    })

  return response
}

const productAddCourse = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'crm/product/course',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add product course err : ', err)

      return {}
    })

  return response
}

const productGalleryAdd = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'crm/product/gallery',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add Gellery image err : ', err)

      return {}
    })

  return response
}

const productGalleryRemove = async (productId, id) => {
  ////await refreshToken()
  const response = await api
    .delete({
      path: 'crm/product/gallery',
      param: `${productId}/${id}`,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add Gellery image err : ', err)

      return {}
    })

  return response
}

const productDrugUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'crm/product/drug',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update drug promotion err : ', err)

      return {}
    })

  return response
}

const productCourseUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'crm/product/course',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update course promotion err : ', err)

      return {}
    })

  return response
}

const productStatusUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'crm/product/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update status product err : ', err)

      return {}
    })

  return response
}

const productAddMember = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'crm/product/member',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add member err : ', err)

      return {}
    })

  return response
}

const productUpdateMember = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'crm/product/member',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('update  member err : ', err)

      return {}
    })

  return response
}

const userOnlineList = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'crm/useronline',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get user online list err : ', err)

      return {}
    })

  return response
}

const reportSaleSummary = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'crm/order',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get user online list err : ', err)

      return {}
    })

  return response
}

const costTransfer = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'recipients',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('add corse transfer err : ', err)

      return {}
    })

  return response
}

const costTransferGet = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'recipients',
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get corse transfer err : ', err)

      return {}
    })

  return response
}

const settingShopGet = async () => {
  ////await refreshToken()
  const response = await api
    .get({
      path: 'shop/crm',
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('get shop crm err : ', err)

      return {}
    })

  return response
}

const settingShopUpdate = async body => {
  ////await refreshToken()
  const response = await api
    .put({
      path: 'shop/crm',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get shop crm err : ', err)

      return {}
    })

  return response
}

const reviewReport = async body => {
  ////await refreshToken()
  const response = await api
    .post({
      path: 'report/crm/review',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('get shop crm err : ', err)

      return {}
    })

  return response
}

export default {
  costTransferGet,
  costTransfer,
  productGet,
  productList,
  productAddMember,
  productAddDrug,
  productCategoryList,
  productAddCourse,
  productGalleryAdd,
  productGalleryRemove,
  productUpdateMember,
  productCourseUpdate,
  productDrugUpdate,
  productStatusUpdate,
  userOnlineList,
  reportSaleSummary,
  settingShopGet,
  settingShopUpdate,
  reviewReport,
}
